import { useLocation, useNavigate } from 'react-router-dom';
import MitsuLogo from '../../../assets/icons/Mitsu';
import WhatsAppImg from '../../../assets/icons/Whatsapp';
import { CLIENT_ROUTES } from '../../../router/routes';
import Typography from '../../base/typography/index';
import css from './index.module.scss';
import { useSearchParam } from 'react-use';

interface IHeaderProps {
  isLogin?: boolean;
}

const Header = (props: IHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = location.pathname.includes(CLIENT_ROUTES.onboarding);

  const hideHeader = useSearchParam('hideHeader')

  const navigateDashboard = () => {
    if (isLoggedIn) {
      navigate(`${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
    }
  };

  return !hideHeader && (
    <div className={css.headerContainer}>
      <div onClick={navigateDashboard} className={css.mitsuLogo} aria-hidden>
        <MitsuLogo />
      </div>
      {isLoggedIn && (
        <div className={css.whatsAppImg}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
          >
            <WhatsAppImg />
          </a>
          <Typography>Support</Typography>
        </div>
      )}
    </div>
  );
};

export default Header;
