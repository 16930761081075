import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdaptiveTypography, Button, Typography } from '../../components/base';
import Image from '../../components/base/image';
import { AppContext } from '../../helpers/hooks/AppContext';
import { CLIENT_ROUTES } from '../../router/routes';
import { getTherapistInfo } from '../../services/callSchedule.service';
import { getCurrentUserId } from '../../services/selfcheckin.service';
import { getUserSteps } from '../../services/users.service';
import style from './scheduleCall.module.scss';
import { useSearchParam } from 'react-use';
import Raksha from '../../assets/images/therapists/Raksha.webp'
import Vidula from '../../assets/images/therapists/Vidula.webp'
import Bijal from '../../assets/images/therapists/Bijal.webp'
import DrJyoti from '../../assets/images/therapists/DrJyoti.webp'

const TherapistDetailStaticPage = () => {
  const [_isButtonEnabled, setButtonEnabled] = useState(false);

  const therapists = {
    'DrJyoti': {
      name: 'Dr. Jyoti Sangle',
      picture: DrJyoti,
      education: `Consulting Psychiatrist
MD (Psychiatry), DPM (Psychological Medicine), MS (Psychotherapy & Counselling)
`,
      introduction: `Dr. Jyoti Sangle is a highly accomplished Mumbai-based psychiatrist with over 25 years of clinical experience, having cared for more than 10,000 patients. A winner of the Major Jenning's Prize and the coveted National Bhagwat Award for the best research paper, She has publications in several national and international psychiatry journals.

A passionate advocate for mental health, Dr. Sangle is also a regular contributor to newspapers and magazines, writing on various aspects of mental well-being.

Dr. Sangle earned her MBBS (1989–1995) and MD in Psychiatry (1995–1998) from the esteemed Topiwala National Medical College in Mumbai.
`
    },
    'Raksha': {
      name: 'Raksha Rajesh',
      picture: Raksha,
      education: `Clinical Psychologist
M.Sc., M. Phil. (Clinical Psychology)
`,
      introduction: `Raksha Rajesh is a Clinical Psychologist with over 6 years of experience in the field of mental health. She practices at the Mitsu.care, Powai and is fluent in English and Hindi.

She specializes in working with adults to address a range of concerns, including anxiety, depression, panic attacks, sleep disturbances, acute stress, relationship and family issues, emotional dysregulation, burnout, workplace stress, dealing with guilt and shame, and self-discovery.

Raksha creates a supportive space where clients can reflect, process their emotions, and build resilience. Believing that "you need to feel it to heal it," she helps clients heal with a caring, whole-person approach.

Raksha completed her M.Sc. in Clinical Psychology from Kasturba Medical College, Mangalore, and her M.Phil. in Clinical Psychology from JSS Academy of Higher Education and Research. She is registered with the Rehabilitation Council of India.
`
    },
    'Vidula': {
      name: 'Vidula Sawant',
      education: `Clinical Psychologist
M.A., M. Phil. (Clinical Psychology)
`,
      picture: Vidula,
      introduction: `Vidula Sawant is a Clinical Psychologist with over 6 years of experience in the field of mental health. She practices at Mitsu.care, Powai and is fluent in English, Hindi and Marathi.

She specializes in working with adults to address a range of concerns, such as, Depression, Anxiety, Panic attacks, Obsessive Compulsive Disorder, Relationship concerns, Acute stress,  Sleep disturbances, workplace burnout, dealing with shame and guilt.

Vidula offers a compassionate and reflective approach to therapy, creating a safe environment for clients to explore their emotions and challenges. With a focus on balance and deeper self-understanding, she works with clients to develop strategies that support emotional health and personal empowerment.

Vidula has completed her M.A. in Applied Psychology from University of Mumbai and her M.Phil. in Clinical Psychology from Post Graduate Institute of Behavioral and Medical Sciences (PGIBAMS) Raipur.  She is registered with the Rehabilitation Council of India.
`
    },
    'Bijal': {
      name: 'Bijal Shah',
      education: `Clinical Psychologist
M.A., M. Phil. (Clinical Psychology)
`,
      picture: Bijal,
      introduction: `Bijal Shah is a Clinical Psychologist with over 4 years of experience in the field of mental health. She practices at Mitsu.care, Powai and is fluent in English, Hindi, and Gujarati.

She specializes in working with individuals across all age groups, addressing concerns such as depression, anxiety, panic attacks, OCD, relationship and marital concerns, personality disorders, dissociative conditions, and sleep disturbances.

Bijal believes everyone has inner strength and works with clients to help them tap into it for healing and growth. She creates a safe, non-judgmental space where clients can explore their feelings and experiences. Inspired by Nietzsche’s quote, “He who has a why to live can bear almost any how,” Bijal helps clients build resilience and develop the skills they need to thrive.

Bijal completed her M.A. in Clinical Psychology from Mumbai University and her M.Phil. in Clinical Psychology from Gwalior Mansik Aarogyashala.
She is registered with the Rehabilitation Council of India.
`
    }
  }

  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

  let therapistInfo = null;
  const { appState, appDispatch } = useContext(AppContext);

  const [isCallCompleted, setIsCallCompleted] = useState(
    appState.stepsData?.stepsCompleted && appState.stepsData?.stepsCompleted >= 4,
  );

  const name = useSearchParam('name') as ('Raksha' | 'Vidula' | 'Bijal');

  therapistInfo = therapists[name!];

  return (
    <div className={style.scheduleCallContainer}>
      <div className={`${style.scheduleCallPageCenter}`}>
        <div className={style.ScheduleCallPage}>
          <div className={style.therapistInfoArranger}> 
            <div className={`${style.scheduleCallHeadContainer}  ${isTwoColumn ? '' : style.alignJustify}`} style={{minWidth:'300px'}}>
              {therapistInfo && (
                <div className={style.therapistInfoBlock}>
                  <Image src={therapistInfo.picture} className={style.therapistImage} />
                  <AdaptiveTypography variant="h4">{therapistInfo.name}</AdaptiveTypography>
                  <AdaptiveTypography variant="label"><span style={{whiteSpace:'break-spaces'}}>{therapistInfo.education}</span></AdaptiveTypography>
                </div>
              )}
            </div>
            <div style={{ height: '20px' }} />

            <div className={`${style.scheduleCallBody} ${style.breakSpaces} ${style.alignJustify}`}>
              <Typography variant="h2">Introduction</Typography>
              <div style={{ height: '10px' }} />
              <AdaptiveTypography variant="body2" mobileVariant="subheading2" className={style.textColorGrey400} >
                <span style={{whiteSpace:'break-spaces'}}>
                {therapistInfo?.introduction}
                </span>
              </AdaptiveTypography>
            </div>
          </div>

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>

      <div className={style.buttonRow}>
        
          <Button
            variant="contained"
            onClick={()=>{
              window.parent.postMessage('close-dialog', 'https://mitsu.care');
            }}
          >
            Close
          </Button>
        
      </div>

    </div>
  );
};

export default TherapistDetailStaticPage;
